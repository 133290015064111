@use "sass:math";

.circle-progress {
    padding-top: 65px;
    padding-bottom: 65px;

    // VARS
    $circle-width: 0.09em;
    $circle-width-hover: 0.07em;

    // colors default
    $circle-primary-color: #000000; // czarny
    $circle-secondary-color: #cdfcc4; //szary bcg
    $circle-bg-color: #ffffff; //srodkowy bezowy

    // colors customized
    $circle-primary-color-green: #1ACB7F;
    $circle-primary-color-span: #1ACB7F;


    // CIRCLE
    // classes 2 extend
    .rect-auto {
        clip: rect(auto, auto, auto, auto);
    }

    .pie {
        position: absolute;
        border: $circle-width solid $circle-primary-color;
        width: 1 - (2 * $circle-width);
        height: 1 - (2 * $circle-width);
        clip: rect(0em, 0.5em, 1em, 0em);
        border-radius: 50%;
        transform: rotate(0deg);
    }

    .pie-fill {
        transform: rotate(180deg);
    }


    // main
    .circle-core {

        *, *:before, *:after {
            box-sizing: content-box;
        }

        position: relative;
        font-size: 200px;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        float: left;
        margin: 0.4em;
        background-color: $circle-secondary-color;

        // 	// center circle to its parent
         	&.center{
         		float: none;
         		margin: 0 auto;
         	}

        // 	&.small{
        // 		font-size: 80px;
        // 	}

        // centered value inside circle
        > span {
            position: absolute;
            width: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            width: 5em;
            line-height: 5em;
            font-size: 0.2em;
            font-weight: bold;
            color: #313131;
            display: block;
            text-align: center;
            white-space: nowrap;
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: ease-out;
        }

        // background inside the circle
        &:after {
            position: absolute;
            top: $circle-width;
            left: $circle-width;
            display: block;
            content: " ";
            border-radius: 50%;
            background-color: $circle-bg-color;
            width: 1 - (2 * $circle-width);
            height: 1 - (2 * $circle-width);
            transition-property: all;
            transition-duration: 0.2s;
            transition-timing-function: ease-in;

        }

        // the slice (mask)
        .slice {
            position: absolute;
            width: 1em;
            height: 1em;
            clip: rect(0em, 1em, 1em, 0.5em);
        }

        // circle to show the status
        .bar {
            @extend .pie;
        }


        // loop to create all needed elements automatically
        @for $j from 51 through 100 {

            &.p#{$j} .slice {
                @extend .rect-auto;
            }

            &.p#{$j} .bar:after {
                @extend .pie-fill;
            }

            &.p#{$j} .fill {
                @extend .pie;
                @extend .pie-fill;
            }

        }

        // loop to rotate all 100 circles
        @for $j from 1 through 100 {
            &.p#{$j} .bar {
                transform: rotate(( math.div(360, 100) * $j) + deg);
            }
        }


        // hover styles
        &:hover {

            cursor: default;

            > span {
                width: 3.33em;
                line-height: 3.33em;
                font-size: 0.3em;
                color: $circle-primary-color-span;
            }

            &:after {
                top: $circle-width-hover;
                left: $circle-width-hover;
                width: 1 - (2 * $circle-width-hover);
                height: 1 - (2 * $circle-width-hover);
            }

        }


        // green skin
        &.green {

            .bar, .fill {
                border-color: $circle-primary-color-green !important;
            }

            &:hover {
                > span {
                    color: $circle-primary-color-span;
                }
            }

        }


    }


}
