.btn {
    border-radius: 10px;
    border: none;

    &--white {
        letter-spacing: 2px;
        background-color: white;
        padding: 5px 15px;
        line-height: 2;
        border-radius: 10px;
        color: #3C4D7B;
    }

    &--gold {
        color: white;
        padding: 10px 20px;
        letter-spacing: 2px;
        background: linear-gradient(90deg, #D7A839 -15%, #F2C14B 121.05%);
        box-shadow: 2px 2px 15px 2px rgba(242, 193, 75, 0.25);
        border: none;
    }

    &--wbs-cta {
        background: #4976E7;
        box-shadow: 5px 5px 15px 2px rgba(73, 118, 231, 0.35);
        font-size: 17px;
        letter-spacing: 0.06em;
        text-transform: capitalize;
        color: white;
        padding: 10px 30px;
        &.submit{
            padding: 10px 30px;
        }
    }

    &--outline-navy{
        &.font-13{
            font-size: 13px;
        }

        border: 1px #324475 solid;
        color: #324475;
    }
}


.tombol {
    font-weight: 700;
    background-color: #4976E7;
    border: none;
    color: white;
    width: 150px;
    padding: 11px 30px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
