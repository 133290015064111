@include media-breakpoint-down(lg) {
    .navbar {
        .navbar-collapse {
            width: 100%;
            background: $navy-blue;
            margin-left: 0;
            margin-right: 0;
            text-align: center;
            z-index: 10;
            position: absolute;
            top: 80px;
            left: 0;
            padding: 30px 10px 25px 10px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;

            .navbar-nav {
                .nav-item {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }
            }

            .dropdown-menu.show {
                text-align: center;
                margin-left: -12px;
                margin-right: -12px;
                border: none;
                border-radius: 0;
                padding-top: 20px;
                padding-bottom: 15px;
                background: #f1f4ff;

                .dropdown-item,
                .nav-link {
                    color: $navy-blue;
                    background: transparent;
                    text-decoration: none;

                    &.active,
                    &:active {
                        font-weight: bold;
                    }
                }

                .dropdown-item {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .dropdown-menu {
                    display: block !important;
                    border-radius: 0 20px 20px 20px;
                    text-align: center;
                    border: 3px solid $navy-blue;
                    background-color: rgba(4, 123, 227, 0.15);
                }

                .mobile-open{
                    padding-left: 20px;
                    padding-right: 20px;
                }

                .pengaduan{
                    display: inline-flex;
                    text-transform: uppercase;
                    font-weight: bold;
                    letter-spacing: 3px;
                    font-size: 16px;
                    padding-bottom: 5px;
                }

                .pengaduan .desktop {
                    display: none;
                }

                .pengaduan .mobile {
                    display: block !important;
                }
            }
        }
    }
}
