.container-wrapper {
    margin-top: 35px;
    margin-bottom: 35px;
    min-height: 70vh;

    header {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    h1 {
        font-size: 36px;
        font-weight: 600;
        color: $blue;
    }

    .main-content {
        h1, h2, h3, h4, h5, h6 {
            color: $blue;
            font-weight: 600;
            margin-bottom: 20px;
            margin-top: 10px;
        }
    }
}

section{
    min-height: 120px;
}

// Maps

.responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    padding-bottom: 50px;
  }

.gmaps {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
  }