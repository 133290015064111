/**
 Menu Navbar
 */
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
    .dropdown-menu li {
        position: relative;
    }
    .nav-item .submenu {
        display: none;
        position: absolute;
        left: 100%;
        top: -7px;
    }
    .nav-item .submenu-left {
        right: 100%;
        left: auto;
    }
    .dropdown-menu > li:hover {
        background-color: #f1f1f1
    }
    .dropdown-menu > li:hover > .submenu {
        display: block;
    }
}

/* ============ desktop view .end// ============ */

/* ============ small devices ============ */
@media (max-width: 991px) {
    .dropdown-menu .dropdown-menu {
        margin-left: 0.7rem;
        margin-right: 0.7rem;
        margin-bottom: .5rem;
    }
}

/* ============ small devices .end// ============ */

.page-header-w-btn {
    padding: 25px 0;
    margin-top: 20px;

    h1 {
        color: #324475;
        font-size: 36px;
        font-weight: 700;
        margin: 0;
    }

    .link-right {
        float: right;
        margin-top: -43px;
        padding: 12px 21px;
        font-weight: 400;
    }

    hr {
        margin-top: 35px;
    }
}

/**
* Article Content
 */
.textarea-content {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #3F4354;

    p {
        margin-bottom: 26px;
    }
}

.response-card {
    margin-bottom: 25px;

    .profile {
        display: flex;
        flex-direction: row;
        margin-bottom: 30px;

        .img-avatar {
            height: 50px;
            width: 50px;
            border-radius: 50px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .bio-box {
            margin-left: 18px;
            padding-top: 7px;

            h4 {
                margin: 0;
                font-size: 16px;
                font-weight: 500;

                .bi {
                    color: #4976E7;
                }
            }

            p {
                margin: 0;
                font-size: 11px;
                color: #BDBDBD;

            }
        }
    }

    .textarea-content {
        margin-bottom: 30px;
    }

    &.dark {
        padding: 25px;
        border-radius: 15px;
        background-color: #F7F8FB;
    }

    &.align-right {
        text-align: right;

        .profile {
            flex-direction: row-reverse;
            margin-left: auto;

            .bio-box {
                margin-left: 0;
                margin-right: 18px;
            }


        }
    }

    &.with-form {
        .form-label {
            h5 {
                font-size: 18px;
                font-weight: bold;

                .bi {
                    font-size: 22px;

                    &::before {
                        vertical-align: -0.380em;
                    }
                }
            }
        }


        input[type=text],
        input[type=url],
        textarea {
            border: 1px solid #D3D6E4;
            box-sizing: border-box;
            border-radius: 10px;
            font-size: 16px;
            padding: 19px;

            &::placeholder {
                color: #E0E0E0;
            }
        }

        input[type=url],
        input[type=text] {
            padding-top: 13px;
            padding-bottom: 13px;
        }

        .btn-submit {
            background: #4976E7;
            box-shadow: 5px 5px 15px 2px rgba(73, 118, 231, 0.35);
            border-radius: 10px;
            color: white;
            padding: 13px 30px;
            float: right;
            margin-top: 25px;
        }


    }
}

.card {
    &.card-15 {
        border: 1px solid #D3D6E4;
        border-radius: 15px;
    }
}
